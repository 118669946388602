<script setup>
import { extractUrlParams } from '@/js/utils'

import Header from '@/components/shared/Header'
import Footer from '@/components/shared/Footer'

const urlParams = extractUrlParams()
const affid = urlParams['affid'] || '1'
const sub_id = urlParams['sub_id'] || '1'

const classActionLink = `https://www.ikd92trk.com/2LQC3F/2PKWQ8/?sub1=${affid}_${sub_id}`

if (affid === '224') {
  if (ttq === undefined) window.loadTikTokPixelScript('CDC51RRC77U1JEFR54LG')
  ttq.track('Contact');
}
</script>

<template>
  <div>
    <Header
      :show-retreaver="false"
      :show-steps="false"
    />
    <main class="thank-you-container container">
      <div class="wrapper">
        <h2 class="header-title">Thank you for your submission.</h2>
        <h3 class="small-header-title">A specialist will be in touch to discuss your potential claim details shortly.</h3>

        <a class="box" target="_blank" :href="classActionLink">
          <div class="card-wrapper">
            <div class="img-container autoaccidentteam-container">
              <img src="@/assets/img/ads/class-action-claim.png" alt="auto accident">
            </div>
            <div class="inner-text-container">
              <button>
                Free Claim Review
              </button>
            </div>
          </div>
        </a>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<style scoped lang="sass">
@import "../assets/style/main"
.thank-you-container
  display: flex
  justify-content: center
  align-items: center
  margin-top: 75px
  background-color: #edf2f7
  max-width: none
  .wrapper
    background-color: #fff
    padding: 40px 50px
    color: #162D59
    border-radius: 5px
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px
    text-align: center
    max-width: 700px
    .header-title
      font-family: Roboto, sans-serif
      font-size: 32px
      font-weight: 400
      line-height: 48px
      margin-bottom: 20px
      margin-top: 0
    .small-header-title
      font-size: 22px
      font-weight: 400
      line-height: 32px
      margin-bottom: 30px
    .img-container img
      width: 100%
      max-width: 480px
    .box .card-wrapper
      margin: 0 auto
      border: 1px solid #d9d9d9
      border-radius: 8px
      max-width: 480px
      -webkit-transform: perspective(1px) translateZ(0)
      transform: perspective(1px) translateZ(0)
      -webkit-transition-duration: 0.3s
      transition-duration: 0.3s
      -webkit-transition-property: transform
      transition-property: all
      &:hover
        -webkit-transform: translateY(-5px)
        transform: translateY(-5px)
        box-shadow: 0 22px 14px -14px rgba(0,0,0,0.4)
        -webkit-box-shadow: 0 22px 14px -14px rgba(0,0,0,0.4)
        -moz-box-shadow: 0 22px 14px -14px rgba(0,0,0,0.4)
      .inner-text-container
        color: #17283e
        display: flex
        justify-content: space-between
        flex-direction: column
        p
          color: inherit
          margin-top: 8px
          font-size: 16px
          line-height: 1.3rem
          text-decoration: none
          text-align: center
        button
          text-decoration: underline
          background-color: #b00001
          cursor: pointer
          padding: 15px
          font-size: 16px
          color: white
          border-radius: .25rem
          font-weight: 700
          border: none
          margin: 20px auto 20px auto
          max-width: 320px
          width: 100%
          transition: background-color .3s
          &:hover
            box-shadow: 0 1px 7px -3px #000000
            background-color: #d1201b

</style>